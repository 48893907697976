<script setup lang="ts">
import type { PageBodyElementCategorySection } from '~~/types/api/page/PageBodyElement'

interface Props {
  id: string
  reverse?: boolean
  content: PageBodyElementCategorySection['value']
}

const props = withDefaults(
  defineProps<Props>(),
  {
    reverse: false,
  },
)

const mainPost = computed(() => {
  if (props.content.posts.length === 4) {
    return props.content.posts[0]
  }

  return null
})

const otherPosts = computed(() => {
  if (mainPost.value) {
    return props.content.posts.slice(1, 4)
  }

  return props.content.posts
})
</script>

<template>
  <div
    class="py-12 md:py-20"
    :class="{ 'bg-peacock-25': props.reverse }"
  >
    <div class="mx-auto max-w-screen-lg space-y-12 px-4">
      <LazyAppPostCard
        v-if="mainPost"
        :image-url="mainPost.featured_image_rendered?.url_relative"
        :image-alt="mainPost.featured_image_rendered?.alt"
        :to="mainPost.html_path"
        :reverse="props.reverse"
        variant="large"
      >
        <template
          v-if="mainPost.tags.length > 0"
          #tag
        >
          {{ mainPost.tags[0].name }}
        </template>

        <template #title>
          {{ mainPost.title }}
        </template>

        <template #subtitle>
          <p v-if="mainPost.sub_title">
            {{ mainPost.sub_title }}
          </p>
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-else
            v-html="mainPost.description"
          />
        </template>
      </LazyAppPostCard>

      <div class="space-y-6">
        <div
          v-if="props.content.section_title || props.content.page.html_path"
          class="flex flex-wrap items-center justify-between gap-4"
        >
          <h2
            v-if="props.content.section_title"
            class="text-2xl font-semibold lg:text-3xl"
          >
            {{ props.content.section_title }}
          </h2>

          <NuxtLink
            v-if="props.content.page.html_path"
            :to="props.content.page.html_path"
            class="btn btn-small btn-secondary"
          >
            {{ $t('links.all') }}
          </NuxtLink>
        </div>

        <div class="no-scrollbar flex overflow-x-auto">
          <div class="flex flex-nowrap gap-6 lg:grid lg:grid-cols-3">
            <LazyAppPostCard
              v-for="post in otherPosts"
              :key="`post_${post.id}`"
              :image-url="post.featured_image_rendered?.url_relative"
              :image-alt="post.featured_image_rendered?.alt"
              :to="post.html_path"
            >
              <template
                v-if="post.tags.length > 0"
                #tag
              >
                {{ post.tags[0].name }}
              </template>

              <template #title>
                {{ post.title }}
              </template>

              <template #subtitle>
                <p v-if="post.sub_title">
                  {{ post.sub_title }}
                </p>
                <div
                  v-else
                  v-html="post.description"
                />
              </template>
            </LazyAppPostCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
